<style lang="scss" scoped>
  .base-checkbox {
    display: flex;
    align-items: flex-start;
    user-select: none;
    cursor: pointer;

    .checkbox-switch {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      background: $brownish-grey;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border-radius: 4px;
    }

    .checkbox-label {
      margin-left: 10px;
    }

    &.active {
      .checkbox-switch {
        background-image: linear-gradient(255deg, var(--theme-color-main), var(--theme-color-additional));
      }
    }
  }
</style>

<template>
  <div class="base-checkbox" :class="classes" @click="toggle">
    <div class="checkbox-switch">
      <icon v-if="value" name="check"/>
    </div>
    <div v-if="$slots.default" class="checkbox-label">
      <slot/>
    </div>
    <div v-else-if="label && label.length" class="checkbox-label">
      {{ label }}
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      default: null,
    },
    label: {
      type: [Number, String],
      default: null,
    },
  },
  methods: {
    toggle() {
      this.$emit('input', !this.value)
    },
  },
  computed: {
    classes() {
      return {
        active: !!this.value,
      }
    },
  },
}
</script>
