<style lang="scss" scoped>
  .loader {
    position: relative;
    width: 1em;
    margin: 0;
    color: inherit;

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    svg {
      animation: rotate 2s linear infinite;
      transform-origin: center center;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;

      circle {
        animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        stroke-linecap: round;
        stroke-width: .2em;
        stroke-miterlimit: 10;
        fill: none;
      }
    }

    &.size-md {
      font-size: 35px;

      svg circle {
        stroke-width: .075em;
      }
    }

    &.size-lg {
      font-size: 70px;

      svg circle {
        stroke-width: .055em;
      }
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  @keyframes color {
    100%,
    0% {
      stroke: var(--theme-color-main);
    }
    40% {
      stroke: var(--theme-color-additional);
    }
    66% {
      stroke: var(--theme-color-main);
    }
    80%,
    90% {
      stroke: var(--theme-color-additional);
    }
  }
</style>

<template>
  <div class="loader" :class="classes">
    <svg viewBox="25 25 50 50">
      <circle cx="50" cy="50" r="20"/>
    </svg>
  </div>
</template>

<script>

export default {
  name: 'ProgressCircle',
  props: {
    size: {
      type: String,
      default: null,
    },
  },
  computed: {
    classes() {
      return {
        [`size-${this.size}`]: !!this.size,
      }
    },
  },
}
</script>
