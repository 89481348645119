import VueRouter from 'vue-router'
import store from '@state/store'
import session from '@services/session'

//// NEW DASHBOARD
import RenderView from '@pages/_RenderView'

// General pages
import Dashboard from '@pages/Dashboard/Dashboard'
import DashboardPage from '@pages/Dashboard/DashboardPage'
import SentDonationsPage from '@pages/Dashboard/SentDonationsPage'
import SendOffersPage from '@pages/Dashboard/SendOffers/SendOffersPage'
import PayoutsPage from '@pages/Dashboard/PayoutsPage'
import ActivityFeedPage from '@pages/Dashboard/ActivityFeedPage'
import MerchandisePage from '@pages/Dashboard/MerchandisePage'
import LegacyPartnershipPage from '@pages/Dashboard/LegacyPartnershipPage'
import ReferralProgramPage from '@pages/Dashboard/ReferralProgramPage'
import DonationPageSettingsPage from '@pages/Dashboard/DonationPageSettingsPage'
import GeneralSettingsPage from '@pages/Dashboard/GeneralSettings/GeneralSettingsPage'
import GeneralSettingsAccountPage from '@pages/Dashboard/GeneralSettings/GeneralSettingsAccountPage'
import GeneralSettingsLinksPage from '@pages/Dashboard/GeneralSettings/GeneralSettingsLinksPage'
import GeneralSettingsAppsPage from '@pages/Dashboard/GeneralSettings/GeneralSettingsAppsPage'
import DonationsSettingsPage from '@pages/Dashboard/DonationsSettingsPage'
import GiveawaysPage from '@pages/Dashboard/GiveawaysPage'
import BalanceHistoryPage from '@pages/Dashboard/BalanceHistoryPage'

// General pages
import MarketplacePage from '@pages/Dashboard/MarketplacePage'

// Widget config pages
import WidgetAlertsPage from '@pages/Dashboard/Widgets/WidgetAlertsPage'
import WidgetStreamStatsLegacyPage from '@pages/Dashboard/Widgets/WidgetStreamStatsLegacyPage'
import WidgetDonationGoalPage from '@pages/Dashboard/Widgets/WidgetDonationGoalPage'
import WidgetRoulettePage from '@pages/Dashboard/Widgets/WidgetRoulettePage'
import WidgetMediaPage from '@pages/Dashboard/Widgets/WidgetMediaPage'
import WidgetPollPage from '@pages/Dashboard/Widgets/WidgetPollPage'
import WidgetStickersPage from '@pages/Dashboard/Widgets/WidgetStickersPage'
import WidgetLastDonationsPage from '@pages/Dashboard/Widgets/WidgetLastDonationsPage'
import WidgetCoronaPage from '@pages/Dashboard/Widgets/WidgetCoronaPage'
import WidgetNewYearPage from '@pages/Dashboard/Widgets/WidgetNewYearPage'
import StreamStatsListPage from '@pages/Dashboard/Widgets/StreamStats/StreamStatsListPage'
import StreamStatsConfigPage from '@pages/Dashboard/Widgets/StreamStats/StreamStatsConfigPage'
import WidgetStreamCreditsPage from '@pages/Dashboard/Widgets/WidgetStreamCreditsPage'

// ChatBot config pages
import ChatBotWrapper from '@pages/Dashboard/ChatBot/ChatBotWrapper'
import ChatBotPage from '@pages/Dashboard/ChatBot/ChatBotPage'
import ChatBotGamePage from '@pages/Dashboard/ChatBot/ChatBotGamePage'

// Offers pages
import OffersPage from '@pages/Dashboard/Offers/OffersPage'
import OffersAvailablePage from '@pages/Dashboard/Offers/OffersAvailablePage'
import OffersActivePage from '@pages/Dashboard/Offers/OffersActivePage'
import OffersFinishedPage from '@pages/Dashboard/Offers/OffersFinishedPage'
import OffersArchivedPage from '@pages/Dashboard/Offers/OffersArchivedPage'
import OffersDetailsPage from '@pages/Dashboard/Offers/OffersDetailsPage'
import OffersSettingsPage from '@pages/Dashboard/Offers/OffersSettingsPage'

// BFT pages
import ChaosTricksPage from '@pages/Dashboard/Bft/BftPage'
import ChaosTricksSettingsPage from '@pages/Dashboard/Bft/BftSettingsPage'
import ChaosTricksStatisticsPage from '@pages/Dashboard/Bft/BftStatisticsPage'
import ChaosTricksHistoryPage from '@pages/Dashboard/Bft/BftHistoryPage'
import ChaosTricksWidgetConfigPage from '@pages/Dashboard/Bft/BftWidgetConfigPage'

// Developer page
import UiKitPage from '@pages/Dashboard/UiKitPage'

const routes = [
  {
    path: `${process.env.VUE_APP_ROOT_PATH}/dashboard/chat-bot/game/:game`,
    component: ChatBotGamePage,
    name: 'dashboard-chat-bot-game',
    props: true,
  },
  {
    path: process.env.VUE_APP_ROOT_PATH,
    meta: {
      profileRequired: true,
    },
    redirect: { name: 'dashboard' },
    component: Dashboard,
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: DashboardPage,
        meta: {
          redirectRules: [
            {
              roles: ['viewer'],
              redirectTo: { name: 'dashboard-sent-donations' },
            },
            {
              roles: ['advertiser'],
              redirectTo: { name: 'dashboard-send-offers' },
            },
          ],
        },
      },
      {
        name: 'dashboard-ui-kit',
        path: 'dashboard/ui-kit',
        component: UiKitPage,
      },
      {
        name: 'dashboard-send-offers',
        path: 'dashboard/send-offers',
        meta: { allowedRoles: ['advertiser'] },
        component: SendOffersPage,
      },
      {
        name: 'dashboard-sent-donations',
        path: 'dashboard/sent-donations',
        meta: { allowedRoles: ['streamer', 'viewer'] },
        component: SentDonationsPage,
      },
      {
        name: 'dashboard-payouts',
        path: 'dashboard/payouts',
        meta: { allowedRoles: ['streamer'] },
        component: PayoutsPage,
      },
      {
        name: 'dashboard-activity-feed',
        path: 'dashboard/activity-feed',
        meta: { allowedRoles: ['streamer'] },
        component: RenderView,
        redirect: { name: 'dashboard-activity-feed-section', params: { section: 'donations' } },
      },
      {
        name: 'dashboard-activity-feed-section',
        path: 'dashboard/activity-feed/:section',
        meta: { allowedRoles: ['streamer'] },
        props: true,
        component: ActivityFeedPage,
      },
      {
        name: 'dashboard-legacy-partnership',
        path: 'dashboard/legacy-partnership',
        meta: { allowedRoles: ['streamer'] },
        component: LegacyPartnershipPage,
      },
      {
        name: 'dashboard-referral-programs',
        path: 'dashboard/referral-program',
        meta: { allowedRoles: ['streamer'] },
        redirect: { name: 'dashboard-referral-program', params: { program: 'meme-alerts' } },
        component: RenderView,
        children: [
          {
            name: 'dashboard-referral-program',
            path: ':program',
            props: true,
            component: ReferralProgramPage,
          },
        ],
      },
      {
        name: 'dashboard-offers',
        path: 'dashboard/offers-v2',
        meta: { allowedRoles: ['streamer'] },
        component: OffersPage,
        redirect: { name: 'dashboard-offers-available' },
        children: [
          {
            path: 'settings',
            name: 'dashboard-offers-settings',
            component: OffersSettingsPage,
          },
          {
            path: 'available',
            name: 'dashboard-offers-available',
            component: OffersAvailablePage,
          },
          {
            path: 'active',
            name: 'dashboard-offers-active',
            component: OffersActivePage,
          },
          {
            path: 'finished',
            name: 'dashboard-offers-finished',
            component: OffersFinishedPage,
          },
          {
            path: 'archived',
            name: 'dashboard-offers-archived',
            component: OffersArchivedPage,
          },
          {
            path: 'details/:id',
            props: true,
            name: 'dashboard-offers-details',
            component: OffersDetailsPage,
            meta: { hide: true },
          },
        ],
      },
      {
        name: 'dashboard-merchandise',
        path: 'dashboard/merchandise',
        meta: { allowedRoles: ['streamer'] },
        component: MerchandisePage,
      },
      {
        name: 'dashboard-donation-page-settings',
        path: 'dashboard/donation-page-settings',
        meta: { allowedRoles: ['streamer'] },
        component: DonationPageSettingsPage,
      },
      {
        name: 'dashboard-partners',
        path: 'dashboard/partners',
        meta: { allowedRoles: ['streamer'] },
        redirect: { name: 'dashboard-chaostricks' },
        component: RenderView,
        children: [
          {
            name: 'dashboard-chaostricks',
            path: 'chaostricks',
            meta: { allowedRoles: ['streamer'] },
            component: ChaosTricksPage,
            redirect: { name: 'dashboard-chaostricks-settings' },
            children: [
              {
                name: 'dashboard-chaostricks-settings',
                path: 'settings',
                component: ChaosTricksSettingsPage,
              },
              {
                name: 'dashboard-chaostricks-statistics',
                path: 'statistics',
                component: ChaosTricksStatisticsPage,
              },
              {
                name: 'dashboard-chaostricks-history',
                path: 'history',
                component: ChaosTricksHistoryPage,
              },
              {
                name: 'dashboard-chaostricks-widget',
                path: 'widget',
                component: ChaosTricksWidgetConfigPage,
              },
            ],
          },
        ],
      },
      {
        name: 'dashboard-donations-settings',
        path: 'dashboard/donations-settings',
        meta: { allowedRoles: ['streamer'] },
        component: RenderView,
        redirect: { name: 'dashboard-donations-settings-section', params: { section: 'synthesis' } },
      },
      {
        name: 'dashboard-donations-settings-section',
        path: 'dashboard/donations-settings/:section',
        meta: { allowedRoles: ['streamer'] },
        props: true,
        component: DonationsSettingsPage,
      },
      {
        path: 'dashboard/marketplace',
        meta: { allowedRoles: ['streamer'] },
        component: RenderView,
        redirect: { name: 'dashboard-marketplace' },
        children: [
          {
            name: 'dashboard-marketplace',
            path: '',
            component: MarketplacePage,
          },
          {
            name: 'dashboard-marketplace-packages',
            path: 'packages/:id?',
            component: MarketplacePage,
          },
        ],
      },
      {
        name: 'dashboard-giveaways',
        path: 'dashboard/giveaways',
        meta: { allowedRoles: ['streamer'] },
        component: GiveawaysPage,
      },
      {
        name: 'dashboard-balance-history',
        path: 'dashboard/balance-history',
        meta: { allowedRoles: ['streamer'] },
        component: BalanceHistoryPage,
      },
      {
        name: 'adv-balance-history',
        path: 'dashboard/adv-balance-history',
        meta: { allowedRoles: ['streamer'] },
        component: BalanceHistoryPage,
      },
      {
        name: 'dashboard-general-settings',
        path: 'dashboard/general-settings',
        meta: { allowedRoles: ['streamer', 'viewer'] },
        redirect: { name: 'dashboard-general-settings-account' },
        component: GeneralSettingsPage,
        children: [
          {
            name: 'dashboard-general-settings-account',
            path: 'account',
            component: GeneralSettingsAccountPage,
            meta: {
              tab: 'account',
            },
          },
          {
            name: 'dashboard-general-settings-links',
            path: 'links',
            component: GeneralSettingsLinksPage,
            meta: {
              tab: 'links',
            },
          },
          {
            name: 'dashboard-general-settings-links-section',
            path: 'links/:section',
            props: true,
            component: GeneralSettingsLinksPage,
            meta: {
              tab: 'links',
            },
          },
          {
            name: 'dashboard-general-settings-apps',
            path: 'apps',
            component: GeneralSettingsAppsPage,
            meta: {
              tab: 'apps',
            },
          },
        ],
      },
      {
        name: 'dashboard-widgets',
        path: 'dashboard/widgets',
        meta: { allowedRoles: ['streamer'] },
        redirect: { name: 'dashboard-widget-alerts' },
        component: RenderView,
        children: [
          {
            name: 'dashboard-widget-alerts',
            path: 'alerts',
            component: WidgetAlertsPage,
          },
          {
            name: 'dashboard-widget-stream-stats-legacy',
            path: 'stream-stats-legacy',
            component: WidgetStreamStatsLegacyPage,
          },
          {
            name: 'dashboard-widget-stream-stats-list',
            path: 'stream-stats',
            component: StreamStatsListPage,
          },
          {
            name: 'dashboard-widget-stream-stats',
            path: 'stream-stats/:id',
            props: true,
            component: StreamStatsConfigPage,
          },
          {
            name: 'dashboard-widget-stream-credits',
            path: 'stream-credits',
            component: WidgetStreamCreditsPage,
          },
          {
            name: 'dashboard-widget-donation-goal',
            path: 'donation-goal',
            component: WidgetDonationGoalPage,
          },
          {
            name: 'dashboard-widget-roulette',
            path: 'roulette',
            component: WidgetRoulettePage,
          },
          {
            name: 'dashboard-widget-media',
            path: 'media',
            component: WidgetMediaPage,
          },
          {
            name: 'dashboard-widget-poll',
            path: 'poll',
            component: WidgetPollPage,
          },
          {
            name: 'dashboard-widget-stickers',
            path: 'stickers',
            component: WidgetStickersPage,
          },
          {
            name: 'dashboard-widget-corona',
            path: 'corona',
            component: WidgetCoronaPage,
          },
          {
            name: 'dashboard-widget-new-year',
            path: 'new-year',
            component: WidgetNewYearPage,
          },
        ],
      },
      {
        name: 'dashboard-chat-bot',
        path: 'dashboard/chat-bot',
        meta: { allowedRoles: ['streamer'] },
        redirect: { name: 'dashboard-chat-bot-section', params: { section: 'accounts' } },
        component: RenderView,
        children: [
          {
            name: 'dashboard-chat-bot-section',
            path: ':section',
            props: true,
            component: ChatBotPage,
          },
        ],
      },
      {
        name: 'dashboard-404',
        path: '*',
        redirect: { name: 'dashboard' },
      },
    ],
  },
]

const createRouter = () => {
  const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
      return savedPosition ?? { x: 0, y: 0 }
    },
  })

  router.beforeEach(async (to, from, next) => {
    await session.onReady()

    if (to.matched.some(record => record.meta.profileRequired)) {
      await store.dispatch('profile/fetchCurrentProfile')
    }

    const routeWithRoles = to.matched.find(r => r.meta.allowedRoles && r.meta.allowedRoles.length)

    const userRoles = store.getters['profile/getUserRoles'] ?? []

    if (routeWithRoles) {
      const { allowedRoles } = routeWithRoles.meta

      if (allowedRoles.length) {
        if (!_.intersection(allowedRoles, userRoles).length) {
          return next({ name: 'dashboard' })
        }
      }
    }

    const routeWithRedirectRules = to.matched.find(r => r.meta.redirectRules && r.meta.redirectRules.length)

    if (routeWithRedirectRules) {
      const { redirectRules = {} } = routeWithRedirectRules.meta

      const matchingRule = redirectRules.find(rule => _.intersection(rule.roles, userRoles).length === userRoles.length)

      if (matchingRule) {
        return next(matchingRule.redirectTo)
      }
    }

    next()
  })

  router.onError(error => {
    console.log(error)
  })

  return router
}

export default createRouter
