<style lang="scss" scoped>
  .dashboard-header {
    width: 100%;
    position: sticky;
    z-index: $dashboard-header-z-index;
    top: 0;
  }

  .dashboard-content {
    @include margin-x(auto);
    display: flex;
    align-items: stretch;
    flex-grow: 1;

    .menu-wrapper {
      align-self: stretch;
      position: relative;
      display: flex;
      flex-direction: column;

      @include media(max, lg) {
        display: none;
      }

      .dashboard-menu {
        flex-grow: 0;
        width: $menu-width;
        z-index: $dashboard-menu-z-index;
        padding-top: 10px;
        padding-bottom: 25px;
      }

      &:before {
        content: '';
      }

      &.fixed {
        .dashboard-menu {
          position: fixed;
          top: 70px;
        }
      }

      &.sticky {
        .dashboard-menu {
          position: sticky;
        }

        &-bottom {
          .dashboard-menu {
            bottom: 0;
          }

          &:before {
            flex-grow: 1;
          }
        }

        &-top {
          .dashboard-menu {
            top: $dashboard-header-height;
          }
        }
      }
    }

    .page-content {
      flex-grow: 1;
      width: 100%;
      margin-top: 30px;
      min-width: 0;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      position: relative;

      .legacy-notifications {
        flex-grow: 0;
      }

      .da-page {
        flex-grow: 1;
      }

      @include media(max, lg) {
        margin-left: 0;
      }
    }

    @include media(min, lg) {
      .menu-wrapper + .page-content {
        margin-left: $dashboard-content-gap;
      }
    }
  }
</style>

<template>
  <dashboard-layout>
    <dashboard-header/>

    <div class="dashboard-content container">
      <div v-if="getUserIsStreamer" class="menu-wrapper" :class="menuClasses">
        <dashboard-menu ref="menu" :style="menuStyles"/>
      </div>

      <div class="page-content">
        <div class="legacy-notifications" ref="legacy"/>
        <global-notifications />
        <router-view :key="$router.path" class="da-page"/>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>

import DashboardMenu from '@components/Dashboard/NewDashboard/DashboardMenu'
import DashboardHeader from '@components/Dashboard/NewDashboard/DashboardHeader/DashboardHeader'

import GlobalNotifications from '@components/BaseComponents/GlobalNotifications'

import { loadLegacyPage } from '@src/services/legacyLoader'

import cookies from '@services/cookies'

const _scroll = {
  old: 0,
  current: 0,
}

export default {
  name: 'Dashboard',
  components: {
    DashboardHeader,
    DashboardMenu,
    GlobalNotifications
  },
  data() {
    return {
      menuFixed: false,
      menuStickyTop: false,
      menuStickyBottom: false,
      menuStay: false,
      menuTopOffset: 0,
      menuBottomOffset: 0,
    }
  },
  computed: {
    ...mapState('application', ['scrollTop']),
    ...mapGetters('profile', ['getUserIsStreamer']),

    menuClasses() {
      return {
        sticky: this.menuStickyBottom || this.menuStickyTop,
        'sticky-bottom': this.menuStickyBottom,
        'sticky-top': this.menuStickyTop,
      }
    },

    menuStyles() {
      return {
        marginTop: `${this.menuTopOffset}px`,
      }
    },
  },
  methods: {
    ...mapActions('dashboard', ['initDashboard']),
    ...mapMutations('dashboard', ['SET_NEW_DASHBOARD']),

    scrollUpdate() {
      _scroll.current = window.scrollY
      this.checkScroll(_scroll.current, _scroll.old)
      _scroll.old = window.scrollY
    },

    checkScroll(scrollTop, oldScrollTop) {
      const [scrollingDown, scrollingUp] = [
        scrollTop > oldScrollTop,
        oldScrollTop > scrollTop,
      ]

      const scrollSpeed = Math.abs(scrollTop - oldScrollTop)

      if (!this.$refs.menu) {
        return
      }

      const $menu = this.$refs.menu.$el

      const {
        top,
        height,
      } = $menu.getBoundingClientRect()

      if (window.innerHeight >= height + 70) {
        this.menuFixed = true
        return
      } else {
        this.menuFixed = false
      }

      if (scrollingDown) {
        this.menuStickyTop = false

        if (!this.menuStickyBottom) {
          this.menuTopOffset = $menu.offsetTop
        }

        if (!this.menuStickyBottom && (top + height - scrollSpeed) <= window.innerHeight) {
          this.menuStickyBottom = true
          this.menuTopOffset = 0
        }
      }

      if (scrollingUp) {
        this.menuStickyBottom = false

        if (!this.menuStickyTop) {
          this.menuTopOffset = $menu.offsetTop
        }

        if (!this.menuStickyTop && (top + scrollSpeed) >= 70) {
          this.menuStickyTop = true
          this.menuTopOffset = 0
        }
      }
    },
  },
  created() {
    if (this.getUserIsStreamer) {
      this.initDashboard()
    }

    this.SET_NEW_DASHBOARD(true)

    const eventAuth = cookies.getItem('event_auth')

    if (eventAuth) {
      this.$ga.event({
        eventCategory: 'event_auth',
        eventAction: eventAuth,
      })

      cookies.removeItem('event_auth')
    }

    const eventRegDone = cookies.getItem('event_reg_done')

    if (eventRegDone) {
      this.$ga.event({
        eventCategory: 'event_reg_done',
      })

      cookies.removeItem('event_reg_done')
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollUpdate)

    if (this.getUserIsStreamer) {
      loadLegacyPage('/dashboard/old-page-critical-notifications', this.$refs.legacy)
    }
  },
  watch: {
    $appHeight: 'scrollUpdate',
  },
}
</script>
