import { mapFields } from '@utils/utils'

const menuSettingsFieldsMap = {
  dashboard: 'index',
  activityFeed: 'activity_feed',
  donationsSettings: 'donation_settings',
  donationPageSettings: 'donation_page',
  generalSettings: 'general_settings',
  payouts: 'payouts',
  offers: 'partnership',
  partnership: 'partnership_legacy',
  referralProgramMemeAlerts: 'referral_program_meme_alerts',
  giveaways: 'giveaways',
  marketplace: 'marketplace',
  merchandise: 'merchandise',
  askMe: 'ask_me',
  bft: 'widgets_bft',
  sentDonations: 'sent_donations',
  widgetAlerts: 'widgets_alerts',
  widgetStreamStats: 'widgets_stream_stats',
  widgetStreamCredits: 'widgets_stream_credits',
  widgetDonationGoal: 'widgets_donation_goal',
  widgetMedia: 'widgets_media',
  widgetPoll: 'widgets_poll',
  widgetStickers: 'widgets_stickers',
  widgetCorona: 'widgets_corona',
  widgetRoulette: 'widgets_roulette',
  widgetNewYear: 'widgets_new_year',
  chatBotAccounts: 'chat_bot_accounts',
  chatBotMultichat: 'chat_bot_multichat',
  chatBotGames: 'chat_bot_games',
  chatBotTimers: 'chat_bot_timers',
  chatBotModerate: 'chat_bot_moderate',
  chatBotCommands: 'chat_bot_commands',
  chatBotPolls: 'chat_bot_polls',
  chatBotKappagen: 'chat_bot_kappagen',
  chatBotTicker: 'chat_bot_ticker',
  help: 'help',
}

export const state = {
  dashboardInfo: {
    unseenNewsPostsCount: 0,
    unseenNotificationsCount: 0,
    hasRussianLinks: 0
  },

  menuSettings: {},

  newDashboard: false,

  banners: {},
}

export const getters = {
  dashboardState: state => ({
    hasUnseenNewsChannelPosts: state.dashboardInfo.unseenNewsPostsCount > 0,
    hasUnseenNotifications: state.dashboardInfo.unseenNotificationsCount > 0,
    hasRussianLinks: state.dashboardInfo.hasRussianLinks > 0
  }),
}

export const mutations = {
  SET_DASHBOARD_INFO(state, data) {
    state.dashboardInfo = data
  },

  SET_MENU_SETTINGS(state, menuSettings) {
    state.menuSettings = menuSettings
  },

  SET_NEW_DASHBOARD(state, value) {
    state.newDashboard = value
  },

  SET_BANNERS(state, value) {
    state.banners = value
  },
}

export const actions = {
  initDashboard({ state, commit, dispatch }) {
    dispatch('fetchDashboardInfo')
    dispatch('currencies/fetchCurrencies', null, { root: true })
  },

  fetchDashboardInfo({ commit }) {
    return api.get('/dashboardinfo')
      .then(({ data }) => {
        const {
          unseen_notifications_count,
          unseen_news_channel_posts_count,
          main_menu,
          banners,
          has_russian_links
        } = data

        commit('SET_BANNERS', banners)

        commit('SET_DASHBOARD_INFO', {
          unseenNotificationsCount: unseen_notifications_count,
          unseenNewsPostsCount: unseen_news_channel_posts_count,
          hasRussianLinks: has_russian_links
        })

        const menuSettings = mapFields(main_menu, menuSettingsFieldsMap, true)

        commit('SET_MENU_SETTINGS', Object.keys(menuSettings).reduce((acc, key) => {
          acc[key] = true
          return acc
        }, {}))
      })
  },

  fetchTelegramChannelNews({ commit, rootGetters }, { page = 1, perPage = 5 } = {}) {
    return api.get('/newschannelpost', {
      params: {
        page,
        per_page: perPage,
        include_timestamps: 1,
      },
    })
      .then(({
        data,
        meta,
      }) => {
        return {
          news: data.map(post => ({
            id: post.id,
            createdAt: post.created_at,
            mediaUrl: post.media_url,
            postDate: post.post_date_ts,
            text: post.text,
          })),
          pagination: {
            page: meta.current_page,
            pagesCount: meta.last_page,
            perPage: meta.per_page,
            total: meta.total,
          },
        }
      })
  },

  markTelegramChannelNewsAsRead({ commit }) {
    return api
      .post('/dashboardinfo', {
        see: 'news_channel_posts',
      })
      .then(({ data }) => {
        const {
          unseen_notifications_count: unseenNotificationsCount,
          unseen_news_channel_posts_count: unseenNewsPostsCount,
        } = data

        commit('SET_DASHBOARD_INFO', {
          unseenNotificationsCount,
          unseenNewsPostsCount,
        })
      })
  },

  fetchUserNotifications({ commit, rootGetters }, { page = 1, perPage = 5 } = {}) {
    return api.get('/usernotification', {
      params: {
        page,
        per_page: perPage,
        include_timestamps: 1,
      },
    })
      .then(({ data, meta }) => {
        return {
          notifications: data.map(notification => ({
            id: notification.id,
            createdAt: notification.created_at,
            isRead: notification.is_read,
            notificationDate: notification.created_at_ts,
            text: notification.text,
          })),
          pagination: {
            page: meta.current_page,
            pagesCount: meta.last_page,
            perPage: meta.per_page,
            total: meta.total,
          },
        }
      })
  },

  markNotificationsAsRead({}, ids) {
    return api.put('/usernotification/updateMany', { ids })
  },

  markAllNotificationsAsRead() {
    return api.put('/usernotification/markReadMany')
  },
}
