<style lang="scss" scoped>
  .dashboard-page {
    position: relative;

    .page-content {
      display: flex;
      align-items: stretch;

      @include media(max, md) {
        flex-direction: column;
      }

      .main-content {
        width: 60%;
        padding-right: 20px;

        @include media(max, md) {
          width: auto;
          padding-right: 0;
        }

        .offers-carousel {
          margin-bottom: 20px;
        }

        .banners-rotation {
          margin-bottom: 20px;
        }

        .legacy-content::v-deep {
          .b-index__content-left {
            width: 100%;
            padding-right: 0;
          }
        }
      }

      .side-content {
        width: 40%;

        @include media(max, md) {
          margin-top: 20px;
          width: auto;
        }

        .legacy-content {
          height: 100%;

          @include media(max, md) {
            height: 700px;
          }

          ::v-deep {
            .b-index, .b-index__inner, .b-index__content, .b-index__content-right {
              height: 100%;
            }

            .b-index__content .b-index__content-right {
              width: 100%;
            }
          }
        }
      }
    }
  }
</style>

<template>
  <div class="dashboard-page">
    <Title type="1">{{ $t('dashboard.dashboard') }}</Title>

    <div class="page-content">
      <div class="main-content">
        <offers-carousel/>
        <banners-rotation/>
        <div class="legacy-content" v-show="!loadingState.legacyMain" ref="legacyMain"/>
      </div>
      <div class="side-content">
        <div class="legacy-content" v-show="!loadingState.legacySide" ref="legacySide"/>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@components/BaseComponents/Title'
import { loadLegacyPage } from '@src/services/legacyLoader'
import OffersCarousel from '@components/Dashboard/NewDashboard/OffersCarousel'
import $ from 'jquery'
import BannersRotation from '@components/Dashboard/NewDashboard/BannersRotation/BannersRotation'


export default {
  name: 'DashboardPage',
  components: { BannersRotation, OffersCarousel, Title },
  data() {
    return {
      loadingState: {
        legacyMain: true,
        legacySide: true,
      },
    }
  },
  metaInfo() {
    return {
      title: this.$t('dashboard.pages.dashboard'),
    }
  },
  mounted() {
    this.loadingState.legacyMain = true

    loadLegacyPage('/dashboard/old-page-index', this.$refs.legacyMain, () => {
      $('.main-content .b-index__content-right').remove()
    })
      .finally(() => {
        this.loadingState.legacyMain = false
      })

    this.loadingState.legacySide = true

    loadLegacyPage('/dashboard/old-page-index', this.$refs.legacySide, () => {
      $('.side-content .b-index__content-left').remove()
    })
      .finally(() => {
        this.loadingState.legacySide = false

        window.activityHeight()
      })
  },
}
</script>
