<style lang="scss" scoped>
  .modal {
    position: fixed;
    color: $bright;

    &.opened {
      display: flex;
      justify-content: center;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 50;
      overflow-y: auto;

      &.centered {
        align-items: center;
      }

      &:not(.centered) {
        align-items: flex-start;
        @include padding-y(70px);
      }
    }

    .modal-overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: rgba(black, .7);
    }

    .modal-box {
      position: relative;
      border-radius: 5px;
      background-color: $black;
      display: flex;
      flex-direction: column;

      @include media(max, md) {
        max-width: 100%;
        margin-left: 10px;
        margin-right: 10px;
      }

      .modal-close-button {
        position: absolute;
        top: 13px;
        right: 13px;
        z-index: 51;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        font-size: 24px;
        color: rgba(white, .3);
        cursor: pointer;

        &:hover {
          color: rgba(white, .6);
        }

        &:active {
          color: rgba(white, .9);
        }
      }

      $modal-spacing: 30px;

      .modal-header {
        padding: $modal-spacing;

        ::v-deep {
          .modal-title {
            font-size: 22px;
            font-weight: 300;
            line-height: 1.36;
          }
        }
      }

      .modal-body {
        flex-grow: 1;
        @include padding-x($modal-spacing);
      }

      .modal-footer {
        padding: $modal-spacing;
      }

      ::v-deep {
        .modal-controls {
          display: flex;

          .button + .button {
            margin-left: 20px;
          }
        }
      }
    }
  }

  .fade {
    &-enter-active, &-leave-active {
      .modal {
        &-overlay, &-box {
          transition: all .2s linear;
        }
      }
    }

    &-enter {
      .modal {
        &-overlay {
          opacity: 0;
        }

        &-box {
          transform: translateY(-20px);
        }
      }
    }

    &-leave-to {
      .modal {
        &-overlay {
          opacity: 0;
        }

        &-box {
          opacity: 0;
          transform: translateY(20px);
        }
      }
    }
  }
</style>

<template>
  <transition name="fade">
    <div
      v-if="value"
      class="modal"
      :class="modalClasses">
      <div
        class="modal-overlay"
        @click="onOverlayClick"></div>
      <div class="modal-box">
        <div
          v-if="!forced"
          class="modal-close-button"
          @click="onCloseButtonClick">
          <icon name="cross"/>
        </div>

        <div v-if="$slots['title']" class="modal-header">
          <div class="modal-title">
            <slot name="title"/>
          </div>
        </div>
        <div v-else-if="$slots['header-content']" class="modal-header">
          <slot name="header-content"/>
        </div>
        <slot v-else name="header"/>

        <div v-if="$slots['body-content']" class="modal-body">
          <slot name="body-content"/>
        </div>
        <slot v-else name="body"/>

        <div v-if="$slots['controls']" class="modal-footer">
          <div class="modal-controls">
            <slot name="controls"/>
          </div>
        </div>
        <div v-else-if="$slots['footer-content']" class="modal-footer">
          <slot name="footer-content"/>
        </div>
        <slot v-else name="footer"/>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    forced: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modalClasses() {
      return {
        opened: this.value,
        centered: this.centered,
      }
    },
  },
  watch: {
    value: {
      handler(value) {
        this.toggleModalCount(value ? 1 : -1)
      },
      immediate: true,
    },
  },
  methods: {
    onOverlayClick() {
      if (!this.forced) {
        this.hideModal()
      }
    },

    onCloseButtonClick() {
      this.$emit('close')
      this.hideModal()
    },

    hideModal() {
      this.$emit('input', false)
    },

    toggleModalCount(way) {
      const prevCounts = Number(document.body.getAttribute('data-modal-count') || 0)
      const currentCounts = prevCounts + way

      if (currentCounts > 0) {
        this.scrollTop = this.scrollTop || window.pageYOffset
        document.body.setAttribute('data-modal-count', currentCounts)
      } else {
        document.body.removeAttribute('data-modal-count')
        this.$nextTick(() => {
          this.scrollTop = null
        })
      }
    },
  },
  beforeDestroy() {
    this.toggleModalCount(-1)
  },
}
</script>
