<style scoped lang="scss">
  .marketplace-page {

  }
</style>

<template>
  <div class="marketplace-page">
    <streamer-market/>
  </div>
</template>

<script>
import frontConfig from '@services/frontConfig'
import Vue from 'vue'
import StreamerMarketDashboard from 'streamer-market-dashboard'
import 'streamer-market-dashboard/dist/main.css';
import store from '@state/store'
import DAUIKit from 'da-uikit'

Vue.use(DAUIKit)

frontConfig.onReady().then(() => {
  const marketplaceConfig = frontConfig.getMarketplaceConfig()

  Vue.use(StreamerMarketDashboard, {
    store,
    accessToken: window.token_dashboard_streamer,
    env: {
      apiUrl: marketplaceConfig.api_url,
      daUrl: frontConfig.getAppUrl(),
    },
    locale: window.document_language,
  });
})

export default {
  name: 'MarketplacePage',
  metaInfo() {
    return {
      title: this.$t('dashboard.pages.marketplace'),
    }
  },
}
</script>
