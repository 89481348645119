<style scoped lang="scss">
  .banner-wrapper {
    position: relative;

    .close-button {
      line-height: 12px;
      position: absolute;
      z-index: 3;
      top: 10px;
      right: 10px;
      font-size: 12px;
      opacity: .7;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }
</style>

<template>
  <div class="banner-wrapper">
    <slot/>
    <div class="close-button" @click="close">
      <icon name="banner-cross"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BannerWrapper',
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
